import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/finally';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { SpinnerServiceAdapter } from "../services/spinner-adapter.service";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { tap } from 'rxjs/operators';
import { AppSettings } from '../../app/AppSettings';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthStorage } from "angular-oauth2-oidc";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  totalRequests = 0;

  constructor(
    public router: Router,
    public AuthService: AuthenticationService,
    public SpinnerService: SpinnerServiceAdapter,
    private oAuthService: AuthService,
    private moduleConfig: OAuthModuleConfig,
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const that = this;

    if (that.showLoader(req.url)) {
      that.totalRequests++;
      that.SpinnerService.emitChange("show");
    }
    
    req = that.setOauth2Request(req);
    return next.handle(req).pipe(
      tap((rsp: HttpResponse<any>) => {
        if (rsp.type === HttpEventType.Response && that.showLoader(req.url)) {
          that.totalRequests--;
          if (that.totalRequests === 0) {
            that.SpinnerService.emitChange("hide");
          }
        }
      },
      (rspError: HttpErrorResponse) => {
        if (that.showLoader(req.url)) {
          that.totalRequests--;
          if (that.totalRequests === 0) {
            that.SpinnerService.emitChange("hide");
          }
        }
        if (rspError.status === 401) {
          this.authStorage.removeItem('access_token');
          this.oAuthService.configureOAuthLogin();
          this.oAuthService.runInitialLogin().then(_ => {
            return Promise.resolve(true);
          });
        }
      })
    ).catch(err => that.errorHandler.handleError(err));
  }

  private showLoader(url: string): boolean {
    let res = true;
    if (
      url.indexOf('authentication/rtts8c09ed38bb654b25a4f2c9923ebb5c8c') > -1 ||
      url.indexOf('user/IsEmailIdAlreadyExist') > -1 ||
      url.indexOf('user/GetUser') > -1 ||
      url.indexOf('forgotUserName/GetUserDetailsFromUsername') > -1 ||
      url.indexOf('SalesCode/IsSalesCodeExist') > -1 ||
      url.indexOf('carriers/SaveCarrierDocumentTypes') > -1 ||
      url.indexOf('agencies/CheckAgencyNameExists') > -1 ||
      url.indexOf('agencies/DoesTaxIdExist') > -1 ||
      url.indexOf('CarriersForms/IsFormCodeExist') > -1 ||
      url.indexOf('CarriersForms/IsFormNameExist') > -1 ||
      url.indexOf('authentication/CurrentPasswordIsValid') > -1 ||
      url.indexOf('forgotUserName/IsUserNameExists') > -1 ||
      url.indexOf('user/isEmailIdExist') > -1 ||
      url.indexOf('carriers/IsCarrierNameExist') > -1 ||
      url.indexOf('carrierPortfolios/IsPortfolioNameExist') > -1 ||
      url.indexOf('agencies/GetAgencyName') > -1 ||
      url.indexOf('agencies/GetAgencyCarrierCodes') > -1 ||
      url.indexOf('agencies/GetTaxIds') > -1 ||
      url.indexOf('agencies/GetAccountNumbers') > -1 ||
      url.indexOf('role/PopulateRoles') > -1 ||
      url.indexOf('products/IsProductNameExist') > -1 ||
      url.indexOf('carriers/IsCarrierContactPersonExist') > -1 ||
      url.indexOf('ProgramType/PopulateProgramTypes') > -1 ||
      url.indexOf('SalesCode/PopulateSalesCodes') > -1 ||
      url.indexOf('documentandformtype/PopulateDocumentAndFormTypes') > -1 ||
      url.indexOf('CoverageLines/PopulateCoverageLine') > -1 ||
      url.indexOf('carriers/PopulateCarriers') > -1 ||
      url.indexOf('carrierPortfolios/PopulateCarrierPortfolios') > -1 ||
      url.indexOf('user/SuggestUser') > -1 ||
      url.indexOf('products/PopulateProducts') > -1 ||
      url.indexOf('agencyBrokers/GetBrokerMasterData') > -1 ||
      url.indexOf('serviceArea/IsServiceAreaExists') > -1 ||
      url.indexOf('subscriptionPlan/IsSubscriptionExist') > -1 ||
      url.indexOf('language/IsLanguageExist') > -1 ||
      url.indexOf('ratingType/IsRatingTypeExist') > -1 ||
      url.indexOf('subscriptionPlan/PopulateSubscriptionPlans') > -1 ||
      url.indexOf('carriersCensus/IsCensusExist') > -1 ||
      url.indexOf('authentication/VerifyOtp') > -1 ||
      url.indexOf('carriersCensus/PopulateCensuses') > -1 ||
      url.indexOf('carrierComposite/PopulateComposite') > -1 ||
      url.indexOf('county/PopulateCounties') > -1 ||
      url.indexOf('forgotPassword/VerifyOtpForResetPassword') > -1 ||
      url.indexOf('zipCode/PopulateZipCodes') > -1 ||
      url.indexOf('products/SaveProductCarrierDocumentTypes') > -1 ||
      url.indexOf('products/SaveProductCensus') > -1 ||
      url.indexOf('products/saveProductForm') > -1 ||
      url.indexOf('products/getCountyList') > -1 ||
      url.indexOf('carriers/SaveCarrierPortfolioDocumentTypes') > -1 ||
      url.indexOf('carriers/SaveCarrierPortfolioFormTypes') > -1 ||
      url.indexOf('tierStructures/PopulateTierStructures') > -1 ||
      url.indexOf('carriers/SaveCarrierPortfolioComposite') > -1 ||
      url.indexOf('carriers/SaveCarrierPortfolioCensus') > -1 ||
      url.indexOf('stateCompositeFactor/PopulateComposites') > -1 ||
      url.indexOf('portfolioCompositeFactor/PopulateComposites') > -1 ||
      url.indexOf('formFireCensus/PopulateCensus') > -1 ||
      url.indexOf('user/getLoginUserName') > -1 ||
      url.indexOf('forgotPassword/CurrentPasswordIsValid') > -1 ||
      url.indexOf('CoverageLines/IsCoverageLineExist') > -1 ||
      url.indexOf('ProgramType/IsProgramTypeExist') > -1 ||
      url.indexOf('County/GetCountiesByZip') > -1 ||
      url.indexOf('agencyBrokers/PopulateBrokers') > -1 ||
      url.indexOf('identity/authorize') > -1 ||
      url.indexOf('identity/connect') > -1 ||
      url.indexOf('impersonation') > -1 
    ) {
      res = false;
    }
    return res;
  }
  
  private setOauth2Request(req: HttpRequest<any>): HttpRequest<any> {
    if (this.oAuthService.isAuthenticated() && this.moduleConfig.resourceServer.sendAccessToken) {
        const token = this.authStorage.getItem('access_token');
        const headers = req.headers.set('Authorization', 'Bearer ' + token);
        req = req.clone({ headers });
    }
    return req;
  }
}
