import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import "rxjs/add/observable/interval";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { AppSettings } from "../app/AppSettings";
import { SharedServices } from "./services/shared.service";
import { AuthService } from "./auth/auth.service";
import { IdentityRolesService } from "./services/identity-roles.service";
import { RoleTypeEnum } from "./shared/enums/RoleTypeEnum";
import { AccessModulesAndAccessRights } from "./shared/module/AccessModulesAndAccessRights";
import { MasterAPICall } from "./shared/master-api.component";
import { RoleBaseAccessRights } from "./shared/role-base-access-rights";
import { AuthConfigService } from "./auth/auth-config.service";
import { SpinnerServiceAdapter  } from './services/spinner-adapter.service';
import { throwError } from 'rxjs';
import { FeatureFlagEnum, FeatureFlagService } from 'src/app/services/featureFlag/feature-flag-service';
import GlobalConfig from 'src/app/global-config';
import * as signalR from '@microsoft/signalr';
import { AuthenticationService } from './services/authentication.service';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "formfire";
  windowScrolled: boolean;
  @ViewChild("contentPopup", { static: true }) content;
  appSettings: AppSettings = new AppSettings();
  loginButtonHide = false;
  updatedSecond: number;
  hideModelPopup = false;
  version: string;
  public accessiableModules: string[];
  public userAccessRights: string[];
  public proxyUserAccessRights: string[];

  isLoading: boolean = false;

  events = ['scroll', 'keydown',"click"];
  worker : any;
  timerStarted : boolean = false;
  countdownMessage: number;
  idleTimeOut: number;
  idleTime: number;
  countdownUnit: string;
  branding: boolean = true;
  allowMultipleSessions: boolean = true;
  private hubConnection: signalR.HubConnection;

  constructor(
    public router: Router,
    private masterApiCall: MasterAPICall,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private sharedService: SharedServices,
    private oauthService: AuthService,
    private identityRoleService: IdentityRolesService,
    public accessRights: RoleBaseAccessRights,
    private authConfig: AuthConfigService,
    private spinnerServiceAdapter: SpinnerServiceAdapter,
    private featureFlagService: FeatureFlagService,
    private  AuthService: AuthenticationService
  ) {
    this.oauthService.configureOAuthLogin();

    this.config.backdrop = "static";

    this.oauthService.hasReceivedToken$.subscribe(data => {
      if (data) {
        this.initiateSession();
      }
    });

    if (this.oauthService.isAuthenticated()) {

      if(this.timerStarted === false)
      {
        this.evaluateInactiveTime();
        this.timerStarted = true;
      }

      this.masterApiCall.CallMasterAPI(this.oauthService.getUserId());      
    }
  }

  ngOnInit() {
    this.spinnerServiceAdapter.changeEmitted$.subscribe(spinnerData => {
      if(spinnerData === "show")
      {
        this.isLoading = true;
      }
      else if(spinnerData === "hide")
      {
        this.isLoading = false;
      }
      else{
        throwError("expected 'show' or 'hide' argument to display spinner")
      }
    })

    this.featureFlagService.featureFlagConfigSubject.subscribe(config => {
      this.branding = this.featureFlagService.isFeatureOn(config.featureFlags, FeatureFlagEnum.Branding);
      this.allowMultipleSessions = this.featureFlagService.featureOn(FeatureFlagEnum.AllowMultipleSessions);
      this.watchSession();
    });

   
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop > 100
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  public initiateSession() {
    return new Promise<void>((resolve, reject) => {
      const that = this;
      if (this.oauthService.isAuthenticated()) {
        this.accessRights.DispalySideBarAndHeader();
        this.evaluateInactiveTime();


        const token = this.oauthService.getToken();

        that.sharedService.SetLoggedInUserRole(token);
        this.masterApiCall.CallMasterAPI(this.oauthService.getUserId());
        that.sharedService.masterApiResolvedCount = 0;


        this.identityRoleService.GetRolesTypes().subscribe(
          (roleTypes) => {
            this.sharedService.GetRoleTypesModel = roleTypes.result;
            const formFireUserRoleType = roleTypes.result.find(
              (rt) => rt.itemName === RoleTypeEnum.FormFireUsers
            ).id;
            this.identityRoleService
              .GetAllAccessModules(formFireUserRoleType)
              .subscribe(
                (data) => {
                  this.accessiableModules = [];
                  const modules = data.result;
                  for (let i = 0, len = modules.length; i < len; i++) {
                    this.accessiableModules.push(modules[i].accessModuleName);
                  }

                  let rights: AccessModulesAndAccessRights[] = [];
                  modules.forEach(
                    (m) =>
                      (rights = rights.concat(
                        m.accessModulesAndAccessRightsList
                      ))
                  );

                  if (rights && rights.length > 0) {
                    this.userAccessRights = [];
                    let i = 0;
                    for (i = 0; i < rights.length; i++) {
                      this.userAccessRights.push(rights[i].accessRightName);
                    }

                    const count = setInterval(() => {
                      if (this.sharedService.masterApiResolvedCount >= 1) {
                        resolve();
                        clearInterval(count);
                      }
                    }, 100);
                  } else {
                    console.error("Access rights not found");
                    resolve();
                  }
                },
                (error) => {
                  console.error(error.error.errorMessage);
                }
              );
          },
          (error) => {
            console.error(error.error.errorMessage);
          }
        );
      } else {
        resolve();
      }
    });
  }

  public getAllAccessModules() {
    this.identityRoleService.GetRolesTypes().subscribe(
      (roleTypes) => {
        const formFireUserRoleType = roleTypes.result.find(
          (rt) => rt.itemName === RoleTypeEnum.FormFireUsers
        ).id;
        this.identityRoleService
          .GetAllAccessModules(formFireUserRoleType)
          .subscribe(
            (data) => {
              this.accessiableModules = [];
              const modules = data.result;
              for (let i = 0, len = modules.length; i < len; i++) {
                this.accessiableModules.push(modules[i].accessModuleName);
              }
            },
            (error) => {
              console.error(error.error.errorMessage);
            }
          );
      },
      (error) => {
        console.error(error.error.errorMessage);
      }
    );
  }

  // get all access Rights based on loged in user role  and access module
  public getAllAccessRights(moduleName: string) {
    this.identityRoleService.GetRolesTypes().subscribe(
      (roleTypes) => {
        const formFireUserRoleType = roleTypes.result.find(
          (rt) => rt.itemName === RoleTypeEnum.FormFireUsers
        ).id;
        this.identityRoleService
          .GetAllAccessModules(formFireUserRoleType)
          .subscribe(
            (data) => {
              this.userAccessRights = [];
              const modules = data.result;
              for (let i = 0, len = modules.length; i < len; i++) {
                this.userAccessRights.push(data[i].accessRightName);
              }

              let rights: AccessModulesAndAccessRights[] = [];
              modules.forEach((m) =>
                rights.concat(m.accessModulesAndAccessRightsList)
              );

              if (rights && rights.length > 0) {
                this.userAccessRights = [];
                let i = 0;
                for (i = 0; i < rights.length; i++) {
                  this.userAccessRights.push(rights[i].accessRightName);
                }
              }
            },
            (error) => {
              console.error(error.error.errorMessage);
            }
          );
      },
      (error) => {
        console.error(error.error.errorMessage);
      }
    );
  }

  // check accessibility for modules on the basis of logedin user role access rights
  public checkAccessFormodule(moduleName): boolean {
    if (
      this.accessiableModules != null &&
      this.accessiableModules !== undefined
    ) {
      let i = 0;
      for (i = 0; i < this.accessiableModules.length; i++) {
        if (this.accessiableModules[i] === moduleName) {
          return true;
        }
      }
    }
    return false;
  }

  // check accessibility for accessrights on the basis of logedin user role base accessmodule
  public checkIsValidAccessRights(accessRightName): boolean {
    if (this.userAccessRights != null && this.userAccessRights !== undefined) {
      let i = 0;
      for (i = 0; i < this.userAccessRights.length; i++) {
        if (this.userAccessRights[i] === accessRightName) {
          return true;
        }
      }
    }
    return false;
  }



  DoLogout() {
    setTimeout(() => {
      this.oauthService.revokeTokenAndLogout();
    }, 500);
  }

  RefreshToken() {
    this.oauthService.refresh();
    this.worker.postMessage({ order:"resetCountdown",  idleTime : this.idleTime, countdownTime : this.idleTimeOut});
  }

  evaluateInactiveTime()
  {
    if(this.timerStarted === false)
    {
      this.timerStarted = true;
      if(typeof Worker !== 'undefined')
      {
        let that = this;
        this.events.forEach(function(event){
          document.addEventListener(event, () => {
            that.resetIdle();
          });
        });

        const tokenTimeout = this.oauthService.getExpireTokenTime();

        this.idleTime = 600; // 10 minutes iddle 
        this.idleTimeOut = 300; // 5 minutes to revert the logout 

        this.idleTime = Number(this.idleTime.toFixed(0));
        this.idleTimeOut =  Number(this.idleTimeOut.toFixed(0));
        this.worker = new Worker(new URL('./app.worker', import.meta.url), { type: 'module' });
        console.log('worker start', new Date());
        this.worker.postMessage({ order:"startIdle", idleTime : this.idleTime, countdownTime : this.idleTimeOut});

         this.worker.onmessage = ({data})=>{
         if(data.event === "idleFinish")
         {
            this.worker.postMessage({ order:"startCountdown", idleTime : this.idleTime, countdownTime : this.idleTimeOut});
            this.loginButtonHide = true;
            this.modalService.open(this.content, { windowClass: 'session-popup' });
         }
         if(data.event === "countDownTick")
         {
          var s = Number(data.seconds);
          if (s > 60) {
            this.countdownMessage = Math.ceil(s / 60);
            this.countdownUnit = 'minutes';
            } else {
            this.countdownMessage = s;
            this.countdownUnit = 'seconds';
          }
         }
         if(data.event === "timeOutFinish")
         {
          this.DoLogout();
         }

        }
      }

      else{
            console.log('Web-worker not supported');
      }
    }

  }

  resetIdle(){
    this.worker.postMessage({ order:"resetIdle",  idleTime : this.idleTime, countdownTime : this.idleTimeOut});
  }


  watchSession() {
   
    if (!this.allowMultipleSessions) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${GlobalConfig.IDENTITY_SERVICE_HOST}sessionhub?sessionId=`+this.oauthService.tokenClaims.sid, {
          accessTokenFactory: async () => this.oauthService.getToken(),
          transport: signalR.HttpTransportType.WebSockets,
          skipNegotiation: true,
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .build();
        
      this.hubConnection.start()
        .then(() => {})
        .catch(err => console.log('Error while starting connection: ' + err));

        this.hubConnection.on("SessionResult", data => {     
          if(data == false) {       
             console.debug('Session Expired');
             this.oauthService.revokeTokenAndLogout();            
            }         
        });
    }

    
  }
  
}
